import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: "AIzaSyD4Nuk56Z0IdXull01shpkLCof2Ly98o40",
  	authDomain: "minibus-travel-ccc15.firebaseapp.com",
  	projectId: "minibus-travel-ccc15",
  	storageBucket: "minibus-travel-ccc15.appspot.com",
  	messagingSenderId: "30296082459",
  	appId: "1:30296082459:web:457dc089ce71dc77e250be"
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

//export const firestore = getFirestore(app)
